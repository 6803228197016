exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-company-career-tsx": () => import("./../../../src/pages/company/career.tsx" /* webpackChunkName: "component---src-pages-company-career-tsx" */),
  "component---src-pages-company-contact-us-tsx": () => import("./../../../src/pages/company/contact-us.tsx" /* webpackChunkName: "component---src-pages-company-contact-us-tsx" */),
  "component---src-pages-company-portfolio-tsx": () => import("./../../../src/pages/company/portfolio.tsx" /* webpackChunkName: "component---src-pages-company-portfolio-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-backend-development-in-surat-gujarat-india-tsx": () => import("./../../../src/pages/services/backend-development-in-surat-gujarat-india.tsx" /* webpackChunkName: "component---src-pages-services-backend-development-in-surat-gujarat-india-tsx" */),
  "component---src-pages-services-graphic-design-in-surat-gujarat-india-tsx": () => import("./../../../src/pages/services/graphic-design-in-surat-gujarat-india.tsx" /* webpackChunkName: "component---src-pages-services-graphic-design-in-surat-gujarat-india-tsx" */),
  "component---src-pages-services-mobile-app-development-in-surat-gujarat-india-tsx": () => import("./../../../src/pages/services/mobile-app-development-in-surat-gujarat-india.tsx" /* webpackChunkName: "component---src-pages-services-mobile-app-development-in-surat-gujarat-india-tsx" */),
  "component---src-pages-services-web-app-design-in-surat-gujarat-india-tsx": () => import("./../../../src/pages/services/web-app-design-in-surat-gujarat-india.tsx" /* webpackChunkName: "component---src-pages-services-web-app-design-in-surat-gujarat-india-tsx" */),
  "component---src-pages-services-web-development-in-surat-gujarat-india-tsx": () => import("./../../../src/pages/services/web-development-in-surat-gujarat-india.tsx" /* webpackChunkName: "component---src-pages-services-web-development-in-surat-gujarat-india-tsx" */),
  "component---src-pages-technologies-tsx": () => import("./../../../src/pages/technologies.tsx" /* webpackChunkName: "component---src-pages-technologies-tsx" */)
}

